/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import StandardLinkPropTypes from '../StandardLinkPropTypes';

function WrappedLink({
  className,
  href,
  target,
  trackingId,
  isNextLink,
  onClick,
  children,
  hasMenu,
  style,
  ...linkProps
}) {
  const linkContent = children;

  if (isNextLink) return children;

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <a
      data-testid="WrappedLink"
      className={className}
      href={href}
      target={target}
      aria-haspopup={hasMenu}
      rel="noopener noreferrer"
      data-trackingid={trackingId}
      onClick={onClick}
      {...linkProps}
    >
      {linkContent}
    </a>
  );
}

WrappedLink.propTypes = StandardLinkPropTypes;

WrappedLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default WrappedLink;
